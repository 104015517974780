<template>
  <div class="Fapply2">
    <van-nav-bar
      title="填写入驻信息"
      :border="false"
      left-arrow
      @click-left="onClickLeft"
    />
    <van-form @submit="onSubmit">
      <van-field
        ref="merchname"
        v-model="merchname"
        name="merchname"
        label="店铺名称"
        placeholder="请输入店铺名"
        :rules="[{ required: true, message: '请输入店铺名' }]"
        @blur="checkMerchname"
      />
      <van-field
        v-model="realname"
        name="realname"
        label="联系人"
        placeholder="请输入联系人姓名"
        :rules="[{ required: true, message: '请输入联系人姓名' }]"
      />
      <van-field
        v-model="umobile"
        name="umobile"
        type="tel"
        label="登陆手机号"
        placeholder="一旦设置无法修改"
        :rules="[{ required: true, message: '请设置您的登录名' }]"
        readonly
      />
      <van-field
        v-model="password"
        type="password"
        name="pwd"
        label="登陆密码"
        placeholder="设置您的密码"
        :rules="[{ required: true, message: '请设置您的密码' }]"
      />
      <van-field
        v-model="password1"
        type="password"
        name="pwd1"
        label="密码确认"
        placeholder="请再次输入您的密码"
        :rules="[{ required: true, message: '请再次输入您的密码' }]"
      />
      <div style="margin: 16px;">
        <van-button block type="danger" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
export default {
  name: "FenxiaoApply2",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      merchname: "",
      realname: "",
      umobile: "",
      password: "",
      password1: "",
      shareid: 0,
      merchid: 0,
      info: {},
      regid: 0
    }
  },
  mounted() {
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "填写入驻信息");
    this.init();
  },
  methods: {
    init() {
      this.getInfo();
    },
    // 获取用户信息
    getInfo() {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/get_fenxiao_reg",
          _that.$qs.stringify({
            type: 1
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.info = res.data.data;
            _that.umobile = _that.info.mobile
            _that.merchname = _that.info.merchname
            _that.realname = _that.info.realname
            _that.regid = _that.info.regid
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    // 商铺名称唯一性判断
    checkMerchname() {
      let _that = null;
      _that = this;
      if (this.merchname) {
        _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/check_merchname",
          _that.$qs.stringify({
            merchname: _that.merchname
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code != 100000) {
            _that.$notify({
              type: "warning",
              message: res.data.msg ? res.data.msg : "店铺名已存在"
            })
            _that.$refs.merchname.focus();
          }
        })
        .catch(error => {
          console.log(error);
        })
      }
    },
    // 点击提交按钮
    onSubmit() {
      let _that = null;
      _that = this;
      if (_that.password !== _that.password1) {
        _that.$toast({
          message: "两次密码输入不一致",
          duration: 1500
        });
        return false;
      }
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/applyshops/apply_fenxiao",
          _that.$qs.stringify({
            merchname: _that.merchname,
            realname: _that.realname,
            umobile: _that.umobile,
            password: _that.password,
            password1: _that.password1,
            sms: _that.sms,
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            _that.$notify({
              type: "success",
              message: res.data.msg ? res.data.msg : "申请成功，等待审核"
            })
            setTimeout(() => {
              this.$router.go(-2);
            }, 2000);
          }else {
            _that.$notify({
              type: "warning",
              message: res.data.msg ? res.data.msg : "申请失败"
            })
          }
        })
        .catch(error => {
          console.log(error);
        })
    }
  }
}
</script>
<style lang="less">
.Fapply2 {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 2;
  background-color: #fff;
  .van-nav-bar .van-icon {
    color: #333;
  }
  .van-cell {
    line-height: 30px;
    .van-cell__value {
      flex: 1;
    }
  }
}
</style>